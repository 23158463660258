import { t, Trans } from "@lingui/macro"
import { LocalizedLink } from "gatsby-theme-i18n"
import React from "react"
import { Seo } from "../components/seo"
import { BaseLayout } from "../layouts/base-layout"

const NFPage = () => {
  return (
    <BaseLayout>
      <Seo
        title={t`Not found`}
        description={t`Find your best profile photo. Whether you're testing professional, social, or dating profile picture, we can help you to make the right impression.`}
        meta={[{ name: "robots", content: "noindex" }]}
      />

      <h1 className="text-4xl font-extrabold">
        <Trans>Page not found</Trans>
      </h1>
      <p className="mt-4">
        Sorry 😔 we couldn’t find what you were looking for.
      </p>

      <LocalizedLink className="mt-4" to="/">
        Go home.
      </LocalizedLink>
    </BaseLayout>
  )
}

export default NFPage
